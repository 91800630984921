import { type ClassValue, clsx } from 'clsx';
import { format, getMonth, getYear, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DateRange } from 'react-day-picker';
import { twMerge } from 'tailwind-merge';
import { routes } from '@frontend/lib/routes';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function upperCaseFirstLetter(str: string) {
  if (!str) return 'Null';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function upperCaseAllWords(str: string) {
  if (!str) return 'Null';
  return str.split(' ').map(upperCaseFirstLetter).join(' ');
}

// Assumes 1000 -> $10.00 like most numbers in our db
export function toDollarString(amount?: number | null): string | null {
  if (amount == null) return null;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount / 100);
}

export function shortenString(str: string, limit = 15) {
  if (str.length <= limit) return str;

  return `${str.slice(0, limit)}...`;
}

export function dateRangeToText(dateRange: DateRange) {
  if (!dateRange.from) return '';

  const isCurrentYear = getYear(dateRange.from) === new Date().getFullYear();
  if (
    dateRange.from &&
    dateRange.to &&
    dateRange.from.toDateString() !== dateRange.to.toDateString()
  ) {
    if (
      getMonth(dateRange.from) === getMonth(dateRange.to) &&
      getYear(dateRange.from) === getYear(dateRange.to)
    ) {
      return `${format(dateRange.from, 'LLL dd')}-${format(
        dateRange.to,
        `dd${isCurrentYear ? '' : ', yyyy'}`
      )}`;
    }
    if (getYear(dateRange.from) === getYear(dateRange.to)) {
      return `${format(dateRange.from, 'LLL dd')}-${format(
        dateRange.to,
        `LLL dd${isCurrentYear ? '' : ', yyyy'}`
      )}`;
    }
    return `${format(dateRange.from, 'LLL dd, yyyy')} - ${format(
      dateRange.to,
      'LLL dd, yyyy'
    )}`;
  } else {
    return format(dateRange.from, `LLL dd${isCurrentYear ? '' : ', yyyy'}`);
  }
}

export function formatPhoneNumber(phone?: string | null) {
  if (!phone) return 'Null';

  // Remove +1 prefix if it exists
  const cleanPhone = phone.startsWith('+1') ? phone.slice(2) : phone;

  // Format the phone number
  return `+1 ${cleanPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}`;
}

export function formatDateTime(date?: string | null, includeTime = true) {
  if (!date) return 'Null';
  const parsedDate = parseISO(date);
  let formattedDate = formatInTimeZone(
    parsedDate,
    'America/New_York',
    'MMM dd, yyyy'
  );
  if (includeTime)
    formattedDate += ` at ${formatInTimeZone(
      parsedDate,
      'America/New_York',
      'hh:mm:ss a'
    )}`;
  return formattedDate;
}

export function termLengthToText(length: number) {
  const years = length === 12 ? '1 year' : `${Math.floor(length / 12)} years`;
  if (length % 12 === 0) {
    return years;
  }
  return years + `, ${length % 12} months`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getNestedProperty(obj: any, path: string) {
  return path.split('.').reduce((current, key) => current && current[key], obj);
}

export function validateEmail(email: string): boolean {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function formatDate(date: string, fmt: string) {
  try {
    return format(parseISO(date), fmt);
  } catch (error) {
    console.error('Error formatting date', error, date, fmt);
    return 'Invalid date';
  }
}

export function describeDifference(oldValue: string, newValue: string): string {
  const oldNum = parseInt(oldValue);
  const newNum = parseInt(newValue);

  if (oldNum === 0) {
    return newNum === 0 ? '0% change' : '>1000% increase';
  }

  const percentChange = ((newNum - oldNum) / Math.abs(oldNum)) * 100;
  const cappedChange = Math.min(Math.max(percentChange, -1000), 1000);
  const roundedChange = Math.abs(Math.round(cappedChange * 10) / 10);

  if (cappedChange > 0) {
    return `${roundedChange}% increase`;
  } else if (cappedChange < 0) {
    return `${roundedChange}% decrease`;
  } else {
    return '0% change';
  }
}

export function getFormattedCardNumber(cardNumber: string) {
  if (cardNumber.length < 16) return cardNumber;
  return `${cardNumber.slice(0, 4)} ${cardNumber.slice(
    4,
    8
  )} ${cardNumber.slice(-8, -4)} ${cardNumber.slice(-4)}`;
}

export const DEFAULT_LOGO_PATH = '/icons/method-logo-negative.svg';

export function getLogoUrl(domain: string) {
  const wellKnownDomains = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'aol.com', // lol
    'icloud.com',
    'protonmail.com',
    'mail.com',
  ];

  if (!domain || wellKnownDomains.includes(domain.toLowerCase())) {
    return DEFAULT_LOGO_PATH;
  }
  return `https://img.logo.dev/${domain}?token=pk_ZO4E6zWXSrWwUdDcSWe8nA`;
}

export function routeExists(path: string) {
  return routes.some((route) => {
    if (route.endsWith('/*')) {
      const baseRoute = route.slice(0, -2);
      return path === baseRoute || path.startsWith(baseRoute + '/');
    }
    return route === path;
  });
}

/**
 * Formats a string by:
 * 1. Replacing special characters with spaces
 * 2. Removing extra spaces
 * 3. Converting to title case
 *
 * Examples:
 * "hello_world" -> "Hello World"
 * "first-name" -> "First Name"
 * "emailAddress" -> "Email Address"
 * "API_KEY_123" -> "Api Key 123"
 * "user.profile.id" -> "User Profile Id"
 */
export function formatName(name: string) {
  if (!name) return 'Null';

  const splitName = name.split(' ');
  if (splitName.every((part) => part === 'null' || part === '')) {
    return '—';
  }

  return name
    .replace(/[^a-zA-Z0-9\s\u2014]/g, ' ') // Replace special chars with spaces
    .replace(/\s+/g, ' ') // Remove extra spaces
    .trim() // Trim leading/trailing spaces
    .toLowerCase() // Convert to lowercase
    .replace(/\b\w/g, (c) => c.toUpperCase()); // Capitalize first letter of each word
}

export function formatPercent(number: number): string {
  if (isNaN(number) || !isFinite(number)) {
    return 'N/A';
  }
  const roundedNumber = Math.round(number * 100) / 100;
  return `${roundedNumber}%`;
}

/**
 * Formats a number by adding commas to it.
 *
 * Examples:
 * "1000" -> "1,000"
 * "123456" -> "123,456"
 * "1000000" -> "1,000,000"
 *
 * @param number The number to format
 * @returns The formatted number
 */
export function formatNumber(number: string) {
  // first round the number
  const roundedNumber = Math.round(parseFloat(number));

  // then format the number
  return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function downloadCSV(csvData: string, fileName: string) {
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const tableHeaders = {
  name: 'Name',
  id: 'ID',
  date: 'Date',
  phone: 'Phone',
  email: 'Email',
  status: 'Status',
  type: 'Type',
  source: 'Source',
  destination: 'Destination',
  amount: 'Amount',
  createdAt: 'Created at',
  updatedAt: 'Updated at',
  balance: 'Balance',
  originalLoanAmount: 'Original loan amount',
  interestRate: 'Interest rate',
  interestRateSource: 'Interest rate source',
  interestRateType: 'Interest rate type',
  last4Digits: 'Last 4 digits',
  openedAt: 'Opened at',
  closedAt: 'Closed at',
  nextPaymentDueDate: 'Next payment due date',
  nextPaymentMinimumAmount: 'Next payment minimum amount',
  lastPaymentAmount: 'Last payment amount',
  lastPaymentDate: 'Last payment date',
  availableCredit: 'Available credit',
  termLength: 'Term length',
  expectedPayoffDate: 'Expected payoff date',
  sequence: 'Sequence',
  creditLimit: 'Credit limit',
  usagePattern: 'Usage pattern',
  subType: 'Sub type',
  disabledAt: 'Disabled at',
  statusReason: 'Status reason',
};

export enum EColumnWidth {
  xs = 1,
  sm = 2,
  md = 3,
  lg = 4,
}

export const getColumnWidth = (width: number): EColumnWidth => {
  switch (width) {
    case 640:
      return EColumnWidth.xs;
    case 768:
      return EColumnWidth.sm;
    case 1024:
      return EColumnWidth.md;
    default:
      return EColumnWidth.lg;
  }
};
